/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Directive, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, startWith, Subscription } from 'rxjs';
import { Product } from '../../../datatypes/product';
import { BasicFeeFormRecord, BasicFeeRecord, BasicFeeTableColumns } from '../../../datatypes/basicfee-record';
import { TaxData } from '../../../datatypes/taxdata';
import { PriceUtils } from '../../../utils/price-utils';
import { DateRangeValidator2Service } from '../../../validation/date-range-validator2.service';
import { DateUtils, FormUtils } from 'pcs-commons/utils';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EditBasicFeeFormManager implements OnDestroy {
  public static readonly BASIC_FEE_FORM_PREFIX = 'baseFeeForm-';
  public toUnsubscribe: Subscription[] = [];
  public dataSourceSubscription: Subscription;

  public basicFeeInfoForm: FormGroup;
  public country: FormControl;
  public currency: FormControl;

  public currentProduct: Product;
  public basicFeeOnProcess: BasicFeeRecord;
  public taxData: TaxData;
  public countries: string[];
  public currencies: string[];

  public currentBasicFees: BasicFeeRecord[] = [];
  public basicFeeParentForm: FormGroup;
  public currentBasicFeeForms = new BehaviorSubject<FormGroup[]>([]);
  public currentBasicFeeForms$ = this.currentBasicFeeForms.asObservable();

  protected constructor(
    public dateRangeValidator2Service: DateRangeValidator2Service,
    public formBuilder: FormBuilder
  ) {}

  public static registerDurationChangeListeners(
    validFromDate: FormControl,
    validFromTime: FormControl,
    validUntilDate: FormControl,
    validUntilTime: FormControl,
    tax: FormControl,
    country: FormControl,
    taxData: TaxData
  ): Subscription[] {
    const subs = [];
    const fromObservable = combineLatest([
      validFromDate.valueChanges.pipe(startWith(validFromDate.value)),
      validFromTime.valueChanges.pipe(startWith(validFromTime.value))
    ]);
    subs.push(
      fromObservable.subscribe(([newValidFromDate, newValidFromTime]) => {
        this.updateTax(country, newValidFromDate, newValidFromTime, validUntilDate, validUntilTime, taxData, tax);
      })
    );
    const untilObservable = combineLatest([
      validUntilDate.valueChanges.pipe(startWith(validUntilDate.value)),
      validUntilTime.valueChanges.pipe(startWith(validUntilTime.value))
    ]);
    subs.push(
      fromObservable.subscribe(([newValidUntilDate, newValidUntilTime]) => {
        this.updateTax(country, newValidUntilDate, newValidUntilTime, validFromDate, validFromTime, taxData, tax);
      })
    );
    return subs;
  }

  private static updateTax(
    country: FormControl<any>,
    newValidFromDate,
    newValidFromTime,
    validUntilDate: FormControl<any>,
    validUntilTime: FormControl<any>,
    taxData: TaxData,
    tax: FormControl<any>
  ): void {
    const countryVal = country.value;
    if (!countryVal) {
      return;
    }
    let taxDateTime = DateUtils.buildDateTimeFromSeparateFields(newValidFromDate, newValidFromTime, false);
    if (!taxDateTime?.isValid) {
      taxDateTime = DateUtils.buildDateTimeFromSeparateFields(validUntilDate.value, validUntilTime.value, true);
    }
    const taxDateTimeAsString = taxDateTime?.isValid ? taxDateTime.toISO() : null;
    console.log('updating tax for time: ', taxDateTime, ' and country: ', countryVal);
    const vatForDateTime = PriceUtils.getTaxForTime(countryVal, taxData, taxDateTimeAsString, true);
    console.log('setting tax to ', vatForDateTime);
    tax.setValue(vatForDateTime * 100);
  }

  public defineBasicFeeConfigsForms(): void {
    const bFeeFromGroups = [];
    console.log('defining basic fee forms for fees: ', this.currentBasicFees);
    this.basicFeeParentForm = new FormGroup(
      {},
      {
        validators: [this.dateRangeValidator2Service.validate.bind(this.dateRangeValidator2Service)]
      }
    );
    this.currentBasicFees.forEach((bFee) => {
      const fg = this.defineFormGroupForBasicFee(bFee);
      bFeeFromGroups.push(fg);
    });
    this.addToParentBasicFeeForm(false, bFeeFromGroups);
    this.updateBasicFeeForms(bFeeFromGroups);
  }

  public ngOnDestroy(): void {
    console.log(this.constructor.name, ': on destroy is called');
    if (this.dataSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  protected defineFormGroupForBasicFee(bFee: BasicFeeRecord): FormGroup {
    // we only need the id for saving - it's not meant to be edited
    const id = new FormControl(bFee.id);
    const validFrom = DateUtils.convertToDateTimeWithUTC(bFee.validFrom);
    const validFromDate = new FormControl(validFrom?.startOf('day'));
    const validFromTime = new FormControl(validFrom?.toFormat('HH:mm:ss'));
    const validUntil = DateUtils.convertToDateTimeWithUTC(bFee.validUntil);
    const validUntilDate = new FormControl(validUntil?.startOf('day'));
    const validUntilTime = new FormControl(validUntil?.toFormat('HH:mm:ss'));
    const vatValue = PriceUtils.getTaxForTime(bFee.country, this.taxData, bFee.validFrom ? bFee.validFrom : bFee.validUntil);
    const tax = new FormControl({ value: vatValue * 100, disabled: true });
    const basicFee = new FormControl(bFee.basicFee, [Validators.required]);
    const basicFeeNetto = new FormControl(PriceUtils.getNettoPrice(bFee.basicFee, vatValue), [Validators.required]);
    const defaultPriceMin = new FormControl(bFee.defaultPriceMin, [Validators.required]);
    const defaultPriceMinNetto = new FormControl(PriceUtils.getNettoPrice(bFee.defaultPriceMin, vatValue), [Validators.required]);
    const defaultPriceKwh = new FormControl(bFee.defaultPriceKwh, [Validators.required]);
    const defaultPriceKwhNetto = new FormControl(PriceUtils.getNettoPrice(bFee.defaultPriceKwh, vatValue), [Validators.required]);

    this.registerValueChangeListenerForPrice(basicFee, basicFeeNetto, tax);
    this.registerValueChangeListenerForPrice(defaultPriceMin, defaultPriceMinNetto, tax);
    this.registerValueChangeListenerForPrice(defaultPriceKwh, defaultPriceKwhNetto, tax);
    const subs = EditBasicFeeFormManager.registerDurationChangeListeners(
      validFromDate,
      validFromTime,
      validUntilDate,
      validUntilTime,
      tax,
      this.country,
      this.taxData
    );
    this.toUnsubscribe.push(...subs);

    const fg = this.formBuilder.group({
      id,
      validFromDate,
      validFromTime,
      validUntilDate,
      validUntilTime,
      tax,
      basicFee,
      basicFeeNetto,
      defaultPriceMin,
      defaultPriceMinNetto,
      defaultPriceKwh,
      defaultPriceKwhNetto
    });

    this.toUnsubscribe.push(tax.valueChanges.subscribe((newVat) => this.updateFormGroupPricesForVat(newVat, fg)));

    return fg;
  }

  protected addToParentBasicFeeForm(clean: boolean, toAdd: FormGroup[]): void {
    if (clean) {
      Object.keys(this.basicFeeParentForm.controls).forEach((name) => this.basicFeeParentForm.removeControl(name));
    }
    toAdd.forEach((fg, index) => this.basicFeeParentForm.addControl(EditBasicFeeFormManager.BASIC_FEE_FORM_PREFIX + index, fg));
  }

  protected updateBasicFeeForms(newFormGroups: FormGroup[]): void {
    console.log('updating basic fee from list: ', newFormGroups);
    this.currentBasicFeeForms.next(newFormGroups);
  }

  protected prepareBasicFee(bFeeForm: FormGroup): BasicFeeRecord {
    const formValue = bFeeForm.value as BasicFeeFormRecord;
    const newBasicFee = new BasicFeeRecord();
    newBasicFee.id = formValue.id;
    newBasicFee.country = this.country.value;
    newBasicFee.currency = this.currency.value;
    const validFromAsDateTime = DateUtils.buildDateTimeFromSeparateFields(
      formValue.validFromDate,
      formValue.validFromTime,
      false
    );
    newBasicFee.validFrom = validFromAsDateTime?.isValid ? validFromAsDateTime.toISO() : null;
    const validUntilAsDateTime = DateUtils.buildDateTimeFromSeparateFields(
      formValue.validUntilDate,
      formValue.validUntilTime,
      true
    );
    newBasicFee.validUntil = validUntilAsDateTime?.isValid ? validUntilAsDateTime.toISO() : null;

    newBasicFee.basicFee = FormUtils.getNullableNumber(bFeeForm, BasicFeeTableColumns.BASIC_FEE);
    newBasicFee.defaultPriceMin = FormUtils.getNullableNumber(bFeeForm, BasicFeeTableColumns.DEFAULT_PRICE_MIN);
    newBasicFee.defaultPriceKwh = FormUtils.getNullableNumber(bFeeForm, BasicFeeTableColumns.DEFAULT_PRICE_KWH);
    return newBasicFee;
  }

  private registerValueChangeListenerForPrice(toListen: FormControl, toUpdate: FormControl, taxCtrl: FormControl): void {
    this.toUnsubscribe.push(
      toListen.valueChanges.subscribe((newVal: number) => {
        console.log('updating netto price for new value: ', newVal);
        let currVat = Number(taxCtrl.value);
        if (!isNaN(newVal) && !isNaN(currVat)) {
          currVat = currVat / 100;
          toUpdate.setValue(PriceUtils.getNettoPrice(newVal, currVat));
        } else {
          toUpdate.setValue(null);
        }
      })
    );
  }

  private updateFormGroupPricesForVat(newVat: number, basicFeeForm: FormGroup): void {
    console.log('updating netto prices for new vat: ', newVat, '%');
    if (newVat) {
      newVat = newVat / 100;
    }
    const basicFee = FormUtils.getNullableNumber(basicFeeForm, BasicFeeTableColumns.BASIC_FEE);
    basicFeeForm.controls[BasicFeeTableColumns.BASIC_FEE_NETTO].setValue(PriceUtils.getNettoPrice(basicFee, newVat));
    const priceMin = FormUtils.getNullableNumber(basicFeeForm, BasicFeeTableColumns.DEFAULT_PRICE_MIN);
    basicFeeForm.controls[BasicFeeTableColumns.DEFAULT_PRICE_MIN_NETTO].setValue(PriceUtils.getNettoPrice(priceMin, newVat));
    const priceKwh = FormUtils.getNullableNumber(basicFeeForm, BasicFeeTableColumns.DEFAULT_PRICE_KWH);
    basicFeeForm.controls[BasicFeeTableColumns.DEFAULT_PRICE_KWH_NETTO].setValue(PriceUtils.getNettoPrice(priceKwh, newVat));
  }
}
