/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { DateTime } from 'luxon';

export function combineDateAndTime(key: string, input: any, millis: 0 | 999): DateTime | undefined {
  const date = input[key] as DateTime;
  const time = input[key.replace('Date', 'Time')] as string | undefined;
  if (date?.isValid && time) {
    let luxonTime = DateTime.fromFormat(time, 'HH:mm:ss', { zone: 'utc' });
    if (!luxonTime.isValid) {
      luxonTime = DateTime.fromFormat(time, 'HH:mm', { zone: 'utc' });
    }
    if (luxonTime.isValid) {
      return date.set({
        hour: luxonTime.hour,
        minute: luxonTime.minute,
        second: luxonTime.second,
        millisecond: millis
      });
    }
    return date.startOf('day');
  }

  return undefined;
}
