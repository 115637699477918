import { QueryHistoryState } from './query-history-state';

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
export class QueryHistoryEntryDto {
  public userId: string;
  public userLogin: string;
  public timestamp: string;
  public resultCount: number;
  public query: string;
  public state: QueryHistoryState;
  public durationInMs: number;

  public static readonly USER_ID = 'userId';
  public static readonly USER_LOGIN = 'userLogin';
  public static readonly TIMESTAMP = 'timestamp';
  public static readonly RESULT_COUNT = 'resultCount';
  public static readonly QUERY = 'query';
  public static readonly STATE = 'state';
  public static readonly DURATION_IN_MS = 'durationInMs';

  public static readonly dataColumns: string[] = [
    QueryHistoryEntryDto.USER_LOGIN,
    QueryHistoryEntryDto.TIMESTAMP,
    QueryHistoryEntryDto.RESULT_COUNT,
    QueryHistoryEntryDto.STATE,
    QueryHistoryEntryDto.DURATION_IN_MS,
    QueryHistoryEntryDto.QUERY
  ];
}
