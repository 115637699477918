<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="importDiv">
  <mat-grid-list cols="10" rowHeight="100px">
    <mat-grid-tile colspan="7">
      <form class="fileNameForm">
        <input type="file" #selectFile name="selectFile" style="visibility: hidden; display:none" accept=".{{fileFormat}}"
               (change)="handleFileSelect()"/>
        <mat-form-field appearance="outline" class="fileNameFormField">
          <input matInput id="fileName" [readonly]="true" class="fileNameInput">
          <button mat-icon-button *ngIf="file" matSuffix (click)="clearFileInput()" class="icon-only clear-button">
            <mat-icon class="clear-button-icon">close</mat-icon>
          </button>
          <mat-hint><strong style="color: red">{{ fileValidMsg | translate :{param0: param} }}</strong></mat-hint>
        </mat-form-field>
      </form>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <div class="buttonDiv">
        <button class="actionButton icon-only" id="selectFileBtn" mat-raised-button (click)="triggerAddFile()"
                [disabled]="isImportDisabled()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
      <div class="buttonDiv">
        <button class="actionButton importButton" mat-raised-button (click)="handleImport()"
                [disabled]="isImportDisabled()">
          {{ 'button.import' | translate }}
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
