import { QueryHistoryState } from './query-history-state';

export class HistoryFilter {
  public userId: string;
  public userLogin: string;
  public fromDate: string;
  public toDate: string;
  public query: string;
  public state: QueryHistoryState;
}
