/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Parameters} from "pcs-commons/global";

/**
 * File Upload form holding text field for file name,
 * plus button showing file selection dialog and import button starting import action
 */
@Component({
  selector: 'import-form-base',
  template: ''
})
export class ImportFormBaseComponent {
  @ViewChild('selectFile') public selectFile;
  public file: File = undefined;
  public fileValidMsg: string;
  @Output() public importEvent = new EventEmitter<File>();
  @Output() public importEventWithArguments = new EventEmitter<any>();
  @Input() public importAllowed: boolean;
  @Input() public disabled: boolean;
  @Input() public fileFormat: string = 'xlsx';
  public param: any = undefined;

  private static validateFile(name: string, fileFormat: string): boolean {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    return ext.toLowerCase() === fileFormat;
  }

  private static updateFileName(file: File): void {
    console.log('Updating file name: ', file ? file.name : null);
    (document.getElementById('fileName') as HTMLInputElement).value = file
      ? file.name
      : null;
  }

  public handleFileSelect(): void {
    const tempFiles = this.selectFile.nativeElement.files;
    if (!tempFiles) {
      return;
    }
    for (const key in tempFiles) {
      if (!isNaN(parseInt(key, 10))) {
        if (!ImportFormBaseComponent.validateFile(tempFiles[key].name, this.fileFormat)) {
          this.showFileValidationMsg('validation.wrongfileformat', this.fileFormat);
          ImportFormBaseComponent.updateFileName(null);
          return;
        }
        if (tempFiles[key].size > Parameters.MAX_FILE_SIZE) {
          const param = Parameters.MAX_FILE_SIZE / Parameters.ONE_MB;
          this.showFileValidationMsg('validation.filetoolarge', param);
          ImportFormBaseComponent.updateFileName(null);
          return;
        }
        this.file = tempFiles[key];
        // we are selecting only one file, so we break after selecting the first one in case there are more
        break;
      }
    }
    console.log(' handling file select event. Files: ' + this.file);
    this.fileValidMsg = undefined;
    ImportFormBaseComponent.updateFileName(this.file);
  }

  private showFileValidationMsg(key: string, param?: any): void {
    this.fileValidMsg = key;
    this.param = param;
    setTimeout(() => {
      this.fileValidMsg = undefined;
      this.param = undefined;
    }, 3000);
  }

  public handleImportWithArguments(additionalArg?: any, additionalArg2?: any): void {
    if (!this.file) {
      this.showFileValidationMsg('validation.nofile');
      return;
    }
    console.log('file', this.file);
    console.log('additionalArg', additionalArg);
    console.log('additionalArg2', additionalArg2);

    this.importEventWithArguments.emit({file: this.file, additionalArg, additionalArg2});
  }

  public handleImport(): void {
    if (!this.file) {
      this.showFileValidationMsg('validation.nofile');
      return;
    }
    this.importEvent.emit(this.file);
  }

  public clearFileInput(): void {
    console.log('clearing file selection');
    this.file = undefined;
    this.fileValidMsg = null;
    this.selectFile.nativeElement.value = '';
    ImportFormBaseComponent.updateFileName(null);
  }

  public triggerAddFile(): void {
    this.selectFile.nativeElement.click();
  }

  public isImportDisabled(): boolean {
    return (!(this.importAllowed === undefined || this.importAllowed === null || this.importAllowed)) || this.disabled;
  }
}
