/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { CountryPriceRecord, CountryPriceRecordColumns } from '../../country-price-record';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProductService } from '../../services/http/product.service';
import { TaxData } from '../../datatypes/taxdata';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { BasicFeeRecord, BasicFeeTableColumns } from '../../datatypes/basicfee-record';
import { SharedDataService } from '../../services/shared-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import { ProductDetailFormManager } from './product-detail-form-manager.abstract';
import { FormBuilder } from '@angular/forms';
import { Action } from '../../datatypes/action.enum';
import { PermissionAware } from '../../permission-aware';
import { AccessRights } from '../../datatypes/access-rights.enum';
import { Product } from '../../datatypes/product';
import { EditBasicFeeComponent } from './edit-basic-fee/edit-basic-fee.component';
import { Utils } from '../../utils/utils';
import { EditCountryPriceComponent } from './edit-country-price/edit-country-price.component';
import { ProductStatus } from '../../datatypes/product-status.enum';
import { NotificationService, NotificationStore } from 'pcs-commons/notification';
import { SalesChannel } from '../../datatypes/sales-channel.enum';
import { AuditService } from '../../services/audit.service';
import { ChangeRequestPayloadType } from '../../datatypes/inbox/change-request-payload-type';
import { ChangeRequestAction } from '../../datatypes/audit-flow/change-request-action';
import { Plan, PlanColumns } from '../../datatypes/plan';
import { Message } from 'pcs-commons/datatypes';

@Component({
  selector: 'pcs-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['../../common/tab-styles.css', '../../dialog/dialog-common-styles.css', './product-detail.component.css']
})
export class ProductDetailComponent extends ProductDetailFormManager implements OnInit, OnDestroy, PermissionAware {
  public toUnsubscribe: Subscription[] = [];

  public readonly reqEditPermission: AccessRights[] = [AccessRights.PRODUCT_EDIT_WEB, AccessRights.FLEET_EDIT_WEB];
  public taxData: TaxData;

  private basicFeesSource = new BehaviorSubject<BasicFeeRecord[]>([]);
  public basicFees$ = this.basicFeesSource.asObservable();
  public basicFeeTableColumns: string[] = [
    BasicFeeTableColumns.COUNTRY,
    BasicFeeTableColumns.CURRENCY,
    BasicFeeTableColumns.TAX,
    BasicFeeTableColumns.VALID_FROM,
    BasicFeeTableColumns.VALID_UNTIL,
    BasicFeeTableColumns.BASIC_FEE,
    BasicFeeTableColumns.BASIC_FEE_NETTO,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN_NETTO,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH_NETTO
  ];

  private countryPricesSource = new BehaviorSubject<CountryPriceRecord[]>([]);
  public countryPrices$ = this.countryPricesSource.asObservable();
  public countryPriceTableColumns: string[] = Object.values(CountryPriceRecordColumns);

  public planTableColumns: string[] = Object.values(PlanColumns);
  private plansSource = new BehaviorSubject<Plan[]>([]);
  public plans$ = this.plansSource.asObservable();

  constructor(
    formBuilder: FormBuilder,
    private productService: ProductService,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    private sharedDataSource: SharedDataService,
    private notificationService: NotificationService,
    private auditService: AuditService
  ) {
    super(formBuilder);
  }

  public async ngOnInit(): Promise<void> {
    this.determineMode();
    this.defineFormControls();
    const product = await this.getProduct();
    this.setupNewProductData(product);
    this.toUnsubscribe.push(this.name.valueChanges.subscribe((value) => (this.currProductData.name = value)));
    this.toUnsubscribe.push(this.term.valueChanges.subscribe((value) => (this.currProductData.term = value)));
    this.toUnsubscribe.push(this.channel.valueChanges.subscribe((value) => (this.currProductData.channel = value)));
    this.toUnsubscribe.push(this.serviceId.valueChanges.subscribe((value) => (this.currProductData.serviceId = value)));
    this.getTaxRecords();
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public activate(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: new Message('product-edit.confirm.productActivation'),
        actionKey: 'global.button.yes'
      }
    });
    dialogRef.afterClosed().subscribe(async (positive) => {
      if (positive) {
        await this.doActivateProduct();
      }
    });
  }

  /*
    this is just to make sure that the tax data is there before starting to render the tables
   */
  public getTaxRecords(): void {
    this.sharedDataSource.currentTaxData$.subscribe((taxData) => (this.taxData = taxData));
  }

  private async getProductDataByCode(): Promise<Product> {
    this.selectedProductCode = this.route.snapshot.paramMap.get('code');
    return await firstValueFrom(this.productService.getProduct(this.selectedProductCode));
  }

  private setupNewProductData(product: Product): void {
    console.log('setup  product ', product);
    this.originalProductData = JSON.parse(JSON.stringify(product));
    this.sharedDataSource.updateCurrentProductOnEdit(product);
    this.setupProductDataSources();
  }

  private determineMode(): void {
    this.mode = Utils.getPageMode(this.route);
    console.log('product details page mode:  ', this.mode);
  }

  public deactivate(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: new Message('product-detail.confirm.productDeactivation'),
        actionKey: 'global.button.yes'
      }
    });
    dialogRef.afterClosed().subscribe(async (positive) => {
      if (positive) {
        await this.doDeactivateProduct();
      }
    });
  }

  private async doActivateProduct(): Promise<void> {
    await this.changeProductStatus(ProductStatus.ACTIVE);
  }

  private async doDeactivateProduct(): Promise<void> {
    await this.changeProductStatus(ProductStatus.DORMANT);
  }

  public async save(): Promise<void> {
    console.log('saving product: ', this.currProductData);
    NotificationStore.instance.clear();
    this.loading = true;
    if (this.mode === Action.EDIT) {
      await this.handleProductUpdate(this.currProductData);
      return;
    }
    this.productService.create(this.currProductData).subscribe({
      next: () => {
        this.loading = false;
        this.goBack();
      },
      error: () => (this.loading = false)
    });
  }

  // Basic Fee
  public onConfigureNewBasicFee(): void {
    this.onEditBasicFee(null);
  }

  public goBack(): void {
    this.location.back();
  }

  public allInputsValid(): boolean {
    return this.productInfoForm.valid;
  }

  public onEditBasicFee(basicFeeRecord: BasicFeeRecord): void {
    this.sharedDataSource.updateCurrentBasicFeeOnEdit(basicFeeRecord);
    const dialogRef = this.dialog.open(EditBasicFeeComponent, { width: '1024px' });
    this.toUnsubscribe.push(
      dialogRef.afterClosed().subscribe((success) => {
        if (success) {
          this.basicFeesSource.next(this.currProductData.basicFees);
          this.priceConfigChanged = true;
        }
      })
    );
  }

  private async handleProductUpdate(toUpdate: Product): Promise<void> {
    if (ProductStatus.DRAFT !== this.originalProductData.status) {
      await this.productService.validate(toUpdate);
      this.initiateChangeRequestForProduct(() => {
        this.loading = false;
      });
    } else {
      try {
        const updatedProduct = await this.productService.update(toUpdate);
        this.originalProductData = JSON.parse(JSON.stringify(updatedProduct));
        this.sharedDataSource.updateCurrentProductOnEdit(updatedProduct);
        this.priceConfigChanged = false;
        this.productInfoForm.markAsPristine();
        this.showInfo(new Message('product-detail.update.success'));
      } finally {
        this.loading = false;
      }
    }
  }

  public onEditCountryPrice(countryPriceRecord: CountryPriceRecord): void {
    this.sharedDataSource.updateCurrentCountryPriceOnEdit(countryPriceRecord);
    const dialogRef = this.dialog.open(EditCountryPriceComponent, { width: '900px', autoFocus: false });
    this.toUnsubscribe.push(
      dialogRef.afterClosed().subscribe((success) => {
        if (success) {
          this.countryPricesSource.next(this.currProductData.prices);
          this.priceConfigChanged = true;
        }
      })
    );
  }

  public onDeleteBasicFee(basicFeeRecord: BasicFeeRecord): void {
    console.log(basicFeeRecord);
    Utils.removeArrayItem(this.currProductData.basicFees, basicFeeRecord);
    this.basicFeesSource.next(this.currProductData.basicFees);
    this.priceConfigChanged = true;
  }

  public onConfigureNewCountryPrice(): void {
    this.onEditCountryPrice(null);
  }

  private async changeProductStatus(newStatus: ProductStatus): Promise<void> {
    const statusBeforeUpdate = this.currProductData.status;
    try {
      this.currProductData.status = newStatus;
      await this.productService.validate(this.currProductData);
      this.initiateChangeRequestForProduct(() => (this.currProductData.status = statusBeforeUpdate));
    } catch (exception) {
      this.currProductData.status = statusBeforeUpdate;
    }
  }

  public onDeleteCountryPrice(countryPriceRecord: CountryPriceRecord): void {
    Utils.removeArrayItem(this.currProductData.prices, countryPriceRecord);
    this.countryPricesSource.next(this.currProductData.prices);
    this.priceConfigChanged = true;
  }

  public isServiceIdRequired(): boolean {
    return this.channel.value !== SalesChannel.PORSCHE_FLEET;
  }

  private initiateChangeRequestForProduct(callbackOnCancel?: () => void): void {
    this.auditService
      .initiateChangeRequest(
        this.currProductData.id,
        this.currProductData,
        this.originalProductData,
        AccessRights.PRODUCT_EDIT_WEB,
        ChangeRequestPayloadType.PRODUCT,
        ChangeRequestAction.MODIFY,
        [this.originalProductData.name, this.originalProductData.code]
      )
      .then((crCreated) => {
        if (crCreated) {
          this.goBack();
        } else if (callbackOnCancel) {
          callbackOnCancel();
        }
      });
  }

  private async getProduct(): Promise<Product> {
    let product: Product;
    if (this.isReadOrEditMode()) {
      product = await this.getProductDataByCode();
    } else {
      product = new Product();
      product.basicFees = [];
      product.prices = [];
      product.plans = [];
      product.status = ProductStatus.DRAFT;
    }
    return product;
  }

  private setupProductDataSources(): void {
    this.toUnsubscribe.push(
      this.sharedDataSource.productToEdit$.subscribe((prod) => {
        this.currProductData = prod;
        this.basicFeesSource.next(prod.basicFees);
        this.countryPricesSource.next(prod.prices);
        this.plansSource.next(prod.plans);
        console.log('got product info for read/edit: ', this.currProductData);
        this.updateFormValuesForProduct();
      })
    );
  }
}
