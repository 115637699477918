/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
export class Parameters {
  public static readonly MANUAL_DATA_PLATFORM = 'INTERNAL';
  public static readonly KEY_ACCESS_TOKEN = 'accessToken';
  public static readonly MAX_QUOTA_ALLOWED = 10000;
  public static readonly MSG_TIMEOUT_MILLIS = 4000;
  public static readonly STATISTIC_SERVICE_TIMEOUT_MILLIS = 300000; // 5 minutes
  public static readonly TARIFF_IMPORT_TIMEOUT_MILLIS = 120000; // 2 minutes
  public static readonly CP_ATTRIBUTE_IMPORT_TIMEOUT_MILLIS = 120000; // 2 minutes
  public static readonly KEY_TAG_IMPORT_TIMEOUT_MILLIS = 120000; // 2 minutes

  public static readonly LOGIN_MIN_LENGTH: any = 5;
  public static readonly LOGIN_MAX_LENGTH: any = 50;
  public static readonly LOGIN_FILTER_UPPERCASE: RegExp = /[A-Z]/;

  public static readonly PASS_MIN_LENGTH: any = 8;
  public static readonly PASS_MAX_LENGTH: any = 256;

  public static readonly PHONE_MIN_LENGTH: any = 5;
  public static readonly PHONE_MAX_LENGTH: any = 20;
  public static readonly PHONE_PATTERN = /[0-9+/-]/;

  public static readonly NUMERIC_PATTERN = /[0-9]/;
  public static readonly DOUBLE_CHARS = /[0-9+-\\.]/;
  public static readonly ADDRESS_FILTER_MAX_LENGTH = 120;
  public static readonly DEFAULT_MAX_LENGTH = 50;
  public static readonly EVSEID_PATTERN: RegExp = /[a-zA-Z0-9 *+%-]/;

  public static readonly CP_ATTRIBUTE_MAX_LENGTH = 350;

  public static readonly MODE_PARAM = 'mode';

  public static readonly GROUP_NAME_MAX_LENGTH: any = 50;

  public static readonly PAGE_SIZE_OPTIONS = [10, 20, 50];
  public static readonly PAGE_SIZE_OPTIONS_MEDIUM = [20, 50, 100];
  public static readonly PAGE_SIZE_OPTIONS_EXTENDED = [10, 20, 50, 100];

  public static readonly HEADER_NO_ERROR_HANDLE = 'noErrorHandling';
  public static readonly HEADER_ERROR_MSG_BASE = 'errorMsgBase';
}
